
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.heart-icon:hover{
 color: #bb2685;
}
.qouteicon{
    color: #bb2685;
}
.author{
    font-family: 'Pacifico', cursive;
    color: #bb2685;
}