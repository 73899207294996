.blog-wrap {
	max-width: 1180px;
	margin: 0 auto;
	
  }
  
  .blog-goBack {
	text-decoration: none;
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
	margin-bottom: 2rem;
	display: block;
  }
  .blog-wrap header {

	text-align: center;
  }
  
  .blog-date {
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
  }
  
  .blog-wrap img {
	width: 50%;
	float: left;
	margin: 20px;
	
  }
  
  .blog-subCategory {
	display: flex;
	justify-content: center;
  }
  .blog-subCategory > div {
	margin: 1rem;
  }
  .blog-desc {
	padding: 1rem;
	margin-top: 1.5rem;
	text-align: justify;
  }
   
  .prog-img{
	border-radius: 10px;
	height: 90vh;
   
  }
  .prog-title{
	background-color:#bb2685;
	border-radius: 5px;
	padding: 5.5rem;
  }
