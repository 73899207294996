.scroll-to-top{
    position: fixed;
    bottom: 5px;
    right: 5%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #540553;
    color: #fff;
    z-index: 1;
    cursor: pointer;
}
.scroll-to-top .i{
    margin-top: 10px;
    animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop{
    from{
    transform: translateY(2px);
    }
    to{
    transform: translateY(-1px);
    }
}