.hypno-img{
   float: left;
}
.descSection p {
   text-align: justify;
}
.paraone{
   background: #d087b6;
   padding: 3rem;
}
.paratwo{
   background: #bb2685;
   padding: 3rem;
}
.parathree{
   background: #d087b6;
   padding: 3rem;
}
.parafour{
   background: #bb2685;
   padding: 3rem;
}
.parafive{
   background: #d087b6;
   padding: 3rem;
}