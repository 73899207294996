.c-stepper__item {
  display: flex;
  gap: 1rem;
}

.c-stepper__title {
  font-size: 50px !important;
  width: 80px;
  height: 80px;
  line-height: 78px;
  display: inline-block;
  border-radius: 80px;
  background: #fff;

  color: #612866;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 14px;
}
.c-stepper__package-name {
  color: #fefefe !important;
  margin-top: 19px !important;
}
.c-stepper__package-desc {
  color: #fefefe !important;
}

.c-stepper__item:before {
  --size: 5rem;
  content: "";
  position: relative;
  cursor: pointer;
  z-index: 1;
  flex: 0 0 var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: lightgrey;
}
.c-stepper__item {
  position: relative;
  display: flex;
  gap: 1rem;
  padding-bottom: 4rem;
}

.c-stepper__item h1 {
  font-size: 18px;
  font-weight: bold;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: absolute;
  --size: 5rem;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(1.5rem);
  width: 2px;
  background-color: #e0e0e0;
}

.c-stepper {
  --size: 3rem;
  --spacing: 0.5rem;
}

.c-stepper__item:not(:last-child):after {
  top: calc(var(--size) + var(--spacing));
  transform: translateX(calc(var(--size) / 2));
  bottom: var(--spacing);
}

.wrapper {
  background: #612866;
  max-width: 2000px;
  padding: 5rem;
  margin: 1rem auto 0;
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 768px;
    padding: 5px;
    margin: 5px;
  }
  .c-stepper__title {
    font-size: 15px;
    width: 70px;
    height: 70px;
    line-height: 68px;
  }
  .c-stepper__package-desc {
    margin-top: 5px;
    margin-bottom: 1rem;
    font-size: 12px;
    padding-right: 1rem;
  }
  .c-stepper__item {
    margin: 10px;
  }
  .c-stepper__item:before {
    --size: 4rem;
    content: "";
    position: relative;
    cursor: pointer;
    z-index: 1;
    flex: 0 0 var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: lightgrey;
  }
}
