@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Yesteryear&display=swap");
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(224, 224, 224) !important;
  background-blend-mode: soft-light;
  background: transparent;
  opacity: 0.9;
}
/* .navbar.active{

  background-color: rgb(255, 252, 252);
} */
.nav-item a:hover {
  color: #fff !important;
  /* padding: 5px 8px !important; */
  background: #540553 !important;
  border-radius: 5px;
}

.navbar-brand {
  font-family: "Yesteryear", cursive;
  font-size: 1.8em;
  font-weight: 400;
  font-style: normal;
  line-height: 1.25em;
  text-decoration: none;
  text-transform: none;
  color: #540553 !important;
}
.nav-item {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
}
.contact-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;

  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: #540553;
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  border: #540553;
  color: #fff;
  transition: all 0.3s ease;
}
.nav-link {
  font-weight: 600;
  color: #bb2685 !important;
  margin-right: 10px;
  border-radius: 5px;
}

.navbar-light .navbar-nav .nav-link.active {
  /* color: rgb(140, 103, 226) !important; */

  color: #fff !important;
  
  background: #540553 !important;

}
/* #nav-link.active {
  color: #fff !important;
  padding: 3px 10px !important;
  background: #b97fb8 !important;
  border-radius: 5px;
  margin-right: 10px;
} */
.navicon{
  color: #540553;
  margin-right: 0.5rem;
  animation: scrollRight 0.5s alternate ease infinite;
}
.navicon:hover{
  color: #fff;
  
}
.megapackage{
  margin-left: -27px;

  cursor: pointer;
}


@keyframes scrollRight{
  from{
  transform: translatex(2px);
  }
  to{
  transform: translatex(-1px);
  }
}