@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Poppins:ital,wght@1,900&family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Yesteryear&display=swap");
@import url("https:/cdn.syncfusion.com/ej2/material.css");

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Josefin+Sans:ital,wght@1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "abel", sans-serif;
}
body {
  background-color: #f5f5f5;
}

#header {
  width: 100%;
  height: 90vh;
  margin-top: 0px;
}
#header h2 {
  background: #540553;
  border-radius: 5px;
  margin-right: 15rem;
  padding-left: 10px;
  color: aliceblue !important;
  opacity: 0.8;
}
#header h1 {
  /* margin: 50px 50px -25px 0px; */
  color: aliceblue !important;
  font-weight: 700;
  border-radius: 5px !important;
  z-index: 5;

  color: #b97fb8;
  font-size: 3.5rem;
  visibility: visible;
  text-align: inherit;
  margin-bottom: 1rem;
  line-height: 100px;
  letter-spacing: 0px;
  font-weight: 700;
  background: #540553;
  border-color: rgb(99, 115, 117);
  border-style: none;
  padding-left: 10px !important;
  border-radius: 0px;
  padding: 0px;
  color: #b97fb8;
  text-decoration: none;
  white-space: normal;
  width: 866px;
  height: auto;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 0.8;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}
#header .brand-name {
  color: #972853;
}
#header .about-brand-name {
  color: #87a4d0;
}
#header .image-fluid {
  width: 100%;
  border-radius: 50%;
}

#header h3 {
  background: #2e3560;
  padding: 1rem 1rem;
  font-family: "Oswald", sans-serif;
  color: aliceblue;
  font-size: 13pt;
  animation-delay: 0.4s;
  border-radius: 5px;
  opacity: 0.8;
}
/* .homePage-title{
  z-index: 5;
  font-family: 'Poppins', sans-serif;
    visibility: visible;
    text-align: inherit;
    margin-bottom: 1rem;
    line-height: 100px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 1rem;
    border-color: rgb(99, 115, 117);
    border-style: none;
    
    border-radius: 0px;
    padding: 0px;
    color: #b97fb8;
    text-decoration: none;
    white-space: normal;
    width: 866px;
    height: auto;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
} */

.btn-get-started {
  font-size: 50px;
  font-size: 16px;

  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background-color: rgb(213 70 0) !important;

  text-decoration: none;
  animation-delay: 0.6s;
}
#header .btn-contact-us {
  font-size: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #87a4d0;
  border: 2px solid #87a4d0;
  text-decoration: none;
  animation-delay: 00.6s;
}

.contact-tag {
  font-family: "Josefin Sans", sans-serif;
  color: #800080;
}

#header .btn-get-started:hover {
  background: #972853;
  color: #fff;
}
#header .btn-contact-us:hover {
  background: #87a4d0;
  color: #fff;
}
#header-img {
  text-align: right;
  width: 100%;
  font-size: 16px;
}

#header .animator {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.anim {
  opacity: 0;
  transform: translateY(30px);
  animation: moveup 0.5s linear forwards;
}
@keyframes moveup {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
label {
  font-size: 20px;
}
.input {
  border-radius: 50%;
}
.contact {
  position: relative;
  min-height: 100vh;
  padding: 25px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* .contact-section {
  margin-top: 5rem !important;
} */
.contact .content {
  text-align: center;
}
.contact .content h2 {
  font-family: "Yesteryear", cursive;
  font-size: 36px;
  font-weight: 500;
  color: #b97fb8;
  text-shadow: #972853;
}
.contact .content p {
  font-weight: 300;
}
.conteiner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-delay: 00.3s;
}
.conteiner .contactInfo {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.conteiner .contactInfo .box {
  position: relative;
  padding: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.conteiner .contactInfo .box .icon {
  min-width: 50px;
  height: 50px;
  display: flex;
  color: #fff;
  background: #b97fb8;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
}
.conteiner .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  flex-direction: column;
}
.conteiner .contactInfo .box .text h3 {
  font-weight: 500;
  color: #0a0808d3;
  padding-top: 2.2rem;
}
.contactForm {
  width: 40%;
  padding: 40px;
  /* background: rgba(20, 19, 19, 0.5); */
}
.contactForm h2 {
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.contactForm .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
  background-color: #f5f5f5;
}
.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}
.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox textarea:focus ~ span {
  color: #000000;
  font-size: 12px;
  transform: translateY(-20px);
}
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:valid ~ span {
  font-size: 12px;
  transform: translateY(-20px);
}
.contactForm .inputBox input[type="submit"] {
  width: 100px;
  background: #b97fb8;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
}
.contactForm .inputBox input[type="submit"]:focus {
  transform: translateY(-5px);
}

.contact-author {
  font-family: "Pacifico", cursive;
  color: #bb2685;
  font-size: 100px;
}
@media (max-width: 991px) {
  .contact {
    padding: 50px;
  }
  .conteiner {
    flex-direction: column;
  }
  .conteiner .contactInfo {
    margin-bottom: 40px;
  }
}

.footer {
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(15px);
  padding: 50px 0;
}

.footer .footerContainer {
  max-width: 1170px;
  margin: auto;
}
.footerRow {
  display: flex;
  flex-wrap: wrap;
}
.footer-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 55px;
}
.footer-menu li {
  padding-right: 20px;
  font-weight: 700;
  list-style: none;
}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col ul {
  list-style: none;
  display: flex;
  align-items: center;
}
.footer-col ul li {
  padding: 10px;
}

.footer-col h4 {
  font-size: 18px;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #972853;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #111111;
  text-decoration: none;
  font-weight: 300;

  display: block;
  transition: all 0.3s ease;
}
.footer-menu ul li a:hover {
  color: #701469;
  transform: scaleX(1.1);
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #070707;
  transition: all 0.3s ease;
}
.footer-col .social-links a:hover {
  color: #f0ecec;
  background-color: #540553;
}
.social-links {
  margin-left: 15px;
}
.footer-follow {
  margin-top: -33px;
}
.rights {
  float: right;
  padding: 10px;
  margin-right: 30px;
  font-size: 15px;
  opacity: 0.9;
}
.reserved {
  float: left;
  padding: 10px;
  margin-right: 20px;
  font-size: 15px;
  opacity: 0.9;
}

.heading h1 {
  font-weight: 500;
  margin: 30px 0;
}
.heading a {
  color: #e50813;
}
.flexSB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MovieBox {
  width: 360px;
  height: auto;
  position: relative;
  transition: 0.5s;
  border-left: 5px solid transparent;
}
.MovieBox:hover {
  cursor: pointer;
  border-left: 5px solid #972853;
  transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(31.25em);
  box-shadow: 0px 0px 0.75em rgba(0, 0, 0, 0.9);
}
.MovieBox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 430px;
  height: 300px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(20, 20, 20, 0.4) 50%,
    rgba(83, 100, 141, 0) 100%
  );
  z-index: -1;
}
.MovieBox img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.MovieBox .text {
  padding: 70px 50px;
  color: #fff;
}
.MovieBox h3 {
  font-weight: 500;
  margin-bottom: 15px;
}
.MovieBox button {
  margin-top: 15px;
  padding: 5px 20px;
  font-size: 13px;
  cursor: pointer;
  background: #972853;
  outline: none;
  border: none;
  color: #fff;
  padding: 12px 40px;
  font-weight: 500;
  font-size: 17px;
}
.MovieBox button i {
  margin-right: 10px;
}

.control-btn button {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.control-btn .next {
  position: absolute;
  top: 50%;
  right: 2%;
}

.control-btn .prev {
  position: absolute;
  top: 50%;
  right: 2%;
  z-index: 99;
}
.heading h1 {
  font-weight: 500;
  margin: 30px 0;
}
.heading a {
  color: #e50813;
}
.clientContainer {
  max-width: 90%;
  margin: auto;
}
.view {
  text-decoration: none;
  color: #070707;
}

.service-text-h {
  font-family: "Yesteryear", cursive;
  font-size: 50px;
  font-weight: 500;
  color: #540553;
  text-shadow: #972853;
}
.service-text-p {
  font-size: 20px;
}
section {
  background-color: #f7f6ff;
}
.faqs {
  margin-top: 10px;
  font-family: "Yesteryear", cursive;
  color: #540553;
}
.date .mbsc-button-icon .mbsc-ltr .mbsc-icon .mbsc-ios {
  color: red;
}
.date
  .mbsc-calendar-cell-text
  .mbsc-calendar-day-text
  .mbsc-ios
  .mbsc-calendar-today {
  background-color: #000000;
}
.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid hsl(240, 1%, 26%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.my-therapy {
  margin-top: 4rem;
  font-family: "Yesteryear", cursive;
  color: #353333;
  font-size: 40px;
}
.sub-button {
  background-color: #540553 !important;
  border-color: #540553 !important;
}

@media (max-width: 768px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
  .footer {
    padding: 0px 0px;
  }
  .footer-follow {
    width: 100%;
  }
  .homePage-title {
    font-size: 2rem;
    width: auto;
    margin-bottom: -0.8rem;
    margin-top: -60px;
  }
  .menu-line-height {
    line-height: 6px;
    background-color: #e3e2e2;
    padding: 13px;
    text-align: right;
    width: 120px;
    border-radius: 5px;
  }
  #header {
    height: 45vh;
  }
  #header h1 {
    margin: 0px 0px 0px 0px;
    font-size: 28px;
  }
  .service-text-h {
    font-size: 30px;
  }
  .navbar-light .navbar-toggler {
    color: #cb02c7 !important;
    border-color: #cb02c7 !important;
  }
}
.faq-section {
  margin-top: 5rem !important;
}

.price-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-btn {
  font-size: 0.9rem !important;
  border: none;
  float: left !important;
  border-radius: 5px;
  padding: 5px;
  padding: 1rem !important;
}
.priceBox {
  border-radius: 5px !important;
}

.price-title {
  font-family: "Yesteryear", cursive !important;
  color: #972853 !important;
}
