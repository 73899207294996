.button {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background-color: rgb(248, 159, 115);
  text-decoration: none;
  animation-delay: 0.6s;
}
.card-img-top{
  height: 200px;
}
.card-body{
  background-color: #d4b5d357;
  
}
.inner{
  overflow: hidden;
   width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.inner{
  transition: all 0.3s ease;
}
.inner:hover{
  transform: scale(1.1);
}
.card-text{
  height: 150px;
}
.padding{
  padding: 1.8px;
}