@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap');
.wd{
    max-width: 48% !important;
    margin-right: 30px;
}
#about-content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#about-content #img-id{
    padding-right: 20px;
    width: 600px;
    height: auto;
    position: relative;
 
}
.content p {
    font-family: 'Mukta', sans-serif;
    text-align: justify;
}

.imagecolum img {
    border-radius: 5px;
    /* box-shadow: ; */
}
@media screen and (max-width: 768px) {
    .wd{
        max-width: 100% !important;
        margin-bottom: 20px;
    }
  }