.modal-content{
    /* position: relative; */
    margin-top: 60px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0
}
.modal-body {
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.bg{
    background-color:#dfdfdf;
    padding-top: 15px;
}
.bg:hover{
    background-color: #d3c5c5;
}
.modal-header {
    display: flex;
    background-color: #540553 ;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.modal.title{
    margin-bottom: 0;
    line-height: 1.5;
    color: rgb(255, 255, 255) !important;
}
#modalRequestLabel{

    color: #e9ecef;
}
.modal-body{
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    width: 100%;
}
.form-group{
    margin-bottom: 1rem;
}
.row{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.appointment-txt{
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .modal-header {
        padding: 10px 20px;
        text-align: center;
        margin-top: 2rem;
    }
    .modal-body {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .rml{
        margin-left: -10px;
        margin-right: -10px;
    }
}